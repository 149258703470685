import React, {useEffect} from 'react';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {MainLayout} from '../../layouts';
import {Location, useLocation, useNavigate} from 'react-router-dom';
import {MonitoringDetailsNavState} from '../../hooks/useMonitoringControl';
import {useGetDynamicQuery, useGetTableQuery} from './store/monitoring.api';
import styles from './styles.module.scss';
import {ScrollUp} from '../../components/scrollUp';
import {Table} from '../../components/table';
import {createLineChart} from '../../lib';

const headMap: { [key: string]: string } = {
    paySum: 'PaySum',
    trx: 'TRX',
    at: 'AT',
    adt: 'ADT',
    upt: 'UPT',
    uptm: 'UPTM',
    paySum1: 'PaySum1',
    trx1: 'TRX1',
    at1: 'AT1',
    adt1: 'ADT1',
    upt1: 'UPT1',
    uptm1: 'UPTM1',
    paySum2: 'PaySum2',
    trx2: 'TRX2',
    at2: 'AT2',
    adt2: 'ADT2',
    upt2: 'UPT2',
    uptm2: 'UPTM2',
    paySum3: 'PaySum3',
    trx3: 'TRX3',
    at3: 'AT3',
    adt3: 'ADT3',
    upt3: 'UPT3',
    uptm3: 'UPTM3',
    paySum4: 'PaySum4',
    trx4: 'TRX4',
    at4: 'AT4',
    adt4: 'ADT4',
    upt4: 'UPT4',
    uptm4: 'UPTM4',
    bonusSum: 'BonusSum',
    bonusPercent: 'BonusPercent',
    avgTRXPerDay: 'AvgTRXPerDay',
    avgPaySumPerDay: 'AvgPaySumPerDay',
    inStorePaySum: 'inStorePaySum',
    inStoreTrx: 'inStoreTrx',
    inStoreAt: 'inStoreAt',
    inStoreAdt: 'inStoreAdt',
    dominoAPPPaySum: 'dominoAPPPaySum',
    dominoAPPTrx: 'dominoAPPTrx',
    dominoAPPAt: 'dominoAPPAt',
    dominoAPPAdt: 'dominoAPPAdt',
    dominoWEBPaySum: 'dominoWEBPaySum',
    dominoWEBTrx: 'dominoWEBTrx',
    dominoWEBAt: 'dominoWEBAt',
    dominoWEBAdt: 'dominoWEBAdt',
    aggregatorsPaySum: 'aggregatorsPaySum',
    aggregatorsTrx: 'aggregatorsTrx',
    aggregatorsAt: 'aggregatorsAt',
    aggregatorsAdt: 'aggregatorsAdt',
    yandexPaySum: 'yandexPaySum',
    yandexTrx: 'yandexTrx',
    yandexAt: 'yandexAt',
    yandexAdt: 'yandexAdt',
    sbermarketPaySum: 'sbermarketPaySum',
    sbermarketTrx: 'sbermarketTrx',
    sbermarketAt: 'sbermarketAt',
    sbermarketAdt: 'sbermarketAdt',
    deliveryPaySum: 'deliveryPaySum',
    deliveryTrx: 'deliveryTrx',
    deliveryAt: 'deliveryAt',
    deliveryAdt: 'deliveryAdt',
    paySumBonus: 'defaultersPaySumBonus',
    trxBonus: 'defaultersTrxBonus',
    atBonus: 'defaultersAtBonus',
    adtBonus: 'defaultersAdtBonus',
    uptBonus: 'defaultersUptBonus',
    uptmBonus: 'defaultersUptmBonus',
    paySumAdmin: 'defaultersPaySumAdmin',
    trxAdmin: 'defaultersTrxAdmin',
    atAdmin: 'defaultersAtAdmin',
    adtAdmin: 'defaultersAdtAdmin',
    uptAdmin: 'defaultersUptAdmin',
    uptmAdmin: 'defaultersUptmAdmin',
};

export type TableData = {
    name: string;
    value: string;
    dynamic: string;
    lastPeriod: string;
}

function cheeseKey(item: any, key: any) {
    return {
        name: item.ObjName,
        value: key in headMap ? item[headMap[key]]?.display : item[key.replace('losses_', 'val_')]?.display,
        dynamic: key in headMap ? item[`${headMap[key]}Dynamic`]?.display : item[key.replace('losses_', 'val_')]?.display,
        lastPeriod: key in headMap ? item[`${headMap[key]}LastPeriod`]?.display : item[key.replace('losses_', 'val_')]?.display,
    };
}

export const MonitoringDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const {state} = location as Location & { state: MonitoringDetailsNavState };

    const {data: dynamic} = useGetDynamicQuery(
        {path: state.dynamicPath},
        {skip: !state.dynamicPath, refetchOnMountOrArgChange: true}
    );
    const {data: table} = useGetTableQuery(
        {path: state.tablePath},
        {skip: !state.tablePath, refetchOnMountOrArgChange: true}
    );

    useEffect(() => {
        if (dynamic) {
            const data = dynamic.graphData.map((i: any) => {
                return {
                    timestamp: i.timestamp,
                    date: i.date,
                    cnt: +i[state.key]?.toFixed(2),
                };
            });

            createLineChart({datasets: data, isMultiple: false});
        }
    }, [dynamic]);


    const tableHead = table ? table[`tableHead${state.key in headMap ? headMap[state.key] : state.key}`]?.map((i: any) => {
        const dateRangeMatch = i?.title.match(/\d{2}\.\d{2}\s*-\s*\d{2}\.\d{2}/);
        if (dateRangeMatch) {
            return dateRangeMatch[0];
        } else {
            return i?.title;
        }

    }) : [];
    const tableBody = table?.tableBody?.map((item: any) => {
        return cheeseKey(item, state.key);
    });

    return (
        <MainLayout>
            <Header breadCrumbs={`${state.labelPage ?? 'Детали'}`}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <div className={styles.chartContainer}>
                <div className={styles.chart} id={'dynamic'}></div>
            </div>
            <div className={styles.wrapper}>
                <Table<TableData>
                    headLabels={tableHead ?? []}
                    body={tableBody ?? []}/>
            </div>
            <ScrollUp/>
        </MainLayout>
    );
};
