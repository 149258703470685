import React from 'react';
import {MainLayout} from '../../layouts';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import styles from './styles.module.scss';
import Footer from '../../components/footer';
import {useNavigate} from 'react-router-dom';
import {useLazyGetMainDataQuery} from './store/monitoring.api';
import {MonitoringDataCard, MonitoringFilter} from './components';
import {useMonitoringControl} from '../../hooks';
import AppLoader from '../../components/appLoader';

export const MonitoringMainData = () => { //@todo надо сказать откуда пришел DP or SC в params
    const navigate = useNavigate();
    const [getMainData, {data, isLoading}] = useLazyGetMainDataQuery();

    const {applyFilter, clearFilter, holding} = useMonitoringControl({

        onFetch: ({params}) => {
            getMainData({params});
        }
    });


    const handleClick = (key: string) => {
        const names: { [key: string]: string } = {
            paySum: 'Выручка за сегодня по объектам',
            at: 'Ср чек за сегодня по объектам',
            trx: 'Кол-во чеков за сегодня по объектам',
            adt: 'Чеков в день/тчк'
        };
        navigate('/view-table', {
            state: {
                path: `/pdfReports/today.php?answerType=2&holding=${holding}`,
                useField: key,
                tableName: names[key] ?? undefined
            }
        });
    };
    return (
        <MainLayout>
            <Header breadCrumbs={`Мониторинг ${holding.toUpperCase()} - Сегодня`}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            <MonitoringFilter
                isFixedDate={true}
                applyFilter={applyFilter}
                clearFilter={clearFilter}
            />
            {isLoading
                ? <AppLoader isFull={false}/> : (
                    <div className={styles.list}>
                        <MonitoringDataCard
                            column={2}
                            num={Math.round(data?.paySum as number)}
                            text={'Выручка'}
                            handleClick={() => handleClick('paySum')}
                        />
                        <MonitoringDataCard
                            column={2}
                            num={data?.trx}
                            text={'Чеков (TRX)'}

                            handleClick={() => handleClick('trx')}/>
                        <MonitoringDataCard
                            column={2}
                            num={data?.at}
                            text={'Сред чек (AT)'}

                            handleClick={() => handleClick('at')}/>
                        <MonitoringDataCard
                            column={2}
                            num={data?.adt}
                            text={'Чеков/дн/тчк (ADT)'}

                            handleClick={() => handleClick('adt')}/>
                    </div>
                )}

            <Footer/>
        </MainLayout>
    );
};
