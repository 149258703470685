import React from 'react';
import Header from '../../components/header';
import {BackButton} from '../../components/backButton';
import {MainLayout} from '../../layouts';
import {useNavigate} from 'react-router-dom';
import {useMonitoringControl} from '../../hooks';
import {useLazyGetBasicStopDataQuery} from './store/monitoring.api';
import AppLoader from '../../components/appLoader';
import styles from './styles.module.scss';
import {MonitoringDataCard} from './components';

export const MonitoringByStop = () => {
    const navigate = useNavigate();

    const [getBasicData, {data, isLoading}] = useLazyGetBasicStopDataQuery();

    const {applyFilter, clearFilter, filter} = useMonitoringControl({
        onFetch: ({params}) => getBasicData({params})
    });


    const handleClick = (type: string) => {
        switch (type) {
            case 'object':
                return navigate('/monitoring-stop-table?type=object');
            case 'delivery':
                return navigate('/monitoring-stop-table?type=delivery');
            case 'hall':
                return navigate('/monitoring-stop-table?type=hall');
            default:
                return navigate('/monitoring-stop-table?type=pickup');
        }

    };

    return (
        <MainLayout>
            <Header breadCrumbs={'Постановки в стоп'}/>
            <BackButton text={'Назад'} onClick={() => navigate(-1)}/>
            {
                isLoading
                    ? <AppLoader isFull={false}/>
                    : (
                        <div className={styles.list}>
                            <MonitoringDataCard
                                column={2}
                                num={data?.object}
                                text={'Обьекты'}
                                handleClick={() => handleClick('object')}
                            />
                            <MonitoringDataCard
                                column={2}
                                num={data?.delivery}
                                text={'Доставка'}
                                handleClick={() => handleClick('delivery')}
                            />
                            <MonitoringDataCard
                                column={2}
                                num={data?.hall}
                                text={'Зал'}
                                handleClick={() => handleClick('hall')}
                            />
                            <MonitoringDataCard
                                column={2}
                                num={data?.pickup}
                                text={'Самовывоз'}
                                handleClick={() => handleClick('pickup')}
                            />

                        </div>
                    )
            }
        </MainLayout>
    );
};

const d = {
    'ID_Obj': 0,
    'ObjName': 'Все',
    'ObjStatusObject': {
        'display': '16',
        'sortValue': 16
    },
    'ObjStatusObjectLastPeriod': {
        'display': '23',
        'sortValue': 23
    },
    'ObjStatusObjectDynamic': {
        'display': '-30.4%',
        'sortValue': -30.434782608696
    },
    'ObjStatusDelivery': {
        'display': null,
        'sortValue': null
    },
    'ObjStatusDeliveryLastPeriod': {
        'display': null,
        'sortValue': null
    },
    'ObjStatusDeliveryDynamic': {
        'display': null,
        'sortValue': null
    },
    'ObjStatusPickup': {
        'display': '1',
        'sortValue': 1
    },
    'ObjStatusPickupLastPeriod': {
        'display': '1',
        'sortValue': 1
    },
    'ObjStatusPickupDynamic': {
        'display': null,
        'sortValue': null
    },
    'ObjStatusHall': {
        'display': '4',
        'sortValue': 4
    },
    'ObjStatusHallLastPeriod': {
        'display': '4',
        'sortValue': 4
    },
    'ObjStatusHallDynamic': {
        'display': null,
        'sortValue': null
    }
};
